import React from "react";
import { graphql } from "gatsby";

import Container from "@components/Container";
import MediaElement from "@components/MediaElement";
import CustomLink from "@components/CustomLink";

import "./teaserblock.scss";

export default function TeaserBlock({ block }) {
  let asset;
  if (block.asset) {
    asset = (
      <div className="asset-wrapper" data-scroll data-scroll-speed="-3">
        <MediaElement media={block.asset} notLazy={true} />
      </div>
    );
  }
  return (
    <section
      data-scroll-section
      className={`block teaser-block ${block.styleGroup_teaser.paddingTop} ${block.styleGroup_teaser.paddingBottom} ${block.styleGroup_teaser.background}`}
    >
      <Container>
        <div
          className="teaser-block__content"
          data-scroll
          data-scroll-speed="2"
        >
          {block.textGroup.pretitle ? (
            <h5 className="pretitle wide-font">{block.textGroup.pretitle}</h5>
          ) : null}
          {block.textGroup.title ? (
            <h2
              className="title"
              dangerouslySetInnerHTML={{ __html: block.textGroup.title }}
            />
          ) : null}
          {block.textGroup.copy ? (
            <p className="copy"> {block.textGroup.copy}</p>
          ) : null}
          {block.textGroup.linkGroup_teaser ? (
            <CustomLink linkData={{ ...block.textGroup.linkGroup_teaser }} />
          ) : null}
        </div>
        {asset}
      </Container>
    </section>
  );
}

export const query = graphql`
  fragment TeaserBlock on PayloadCMS_TeaserBlock {
    blockType
    asset {
      ...Media
    }
    textGroup {
      linkGroup_teaser {
        link_label
        link_target_hash
        link_target_external
        link_target_page_hash
        link_type
        link_target_page {
          slug
        }
        link_target_project {
          slug
        }
      }
      copy
      pretitle
      title
    }
    styleGroup_teaser {
      background
      paddingBottom
      paddingTop
    }
  }
`;
