import React from "react";
import { graphql } from "gatsby";

import useWindowSize from "@src/hooks/useWindowSize";
import HorizontalSlideDesktop from "./HorizontalSlideDesktop";
import HorizontalSlideMobile from "./HorizontalSlideMobile";
import "./horizontalslideblock.scss";

export default function HorizontalSlideBlock({ block }) {
  const size = useWindowSize();

  return (
    <section
      data-scroll-section
      className={`block horizontal-slide-block ${block.styleGroup_horizontalSlide.paddingTop} ${block.styleGroup_horizontalSlide.paddingBottom}`}
    >
      {size.width >= 1024 ? (
        <HorizontalSlideDesktop block={block} />
      ) : (
        <HorizontalSlideMobile block={block} />
      )}
    </section>
  );
}

export const query = graphql`
  fragment HorizontalSlideBlock on PayloadCMS_HorizontalSlideBlock {
    blockName
    blockType
    slides {
      asset {
        ...Media
      }
      textGroup {
        copy
        pretitle
        title
      }
    }
    styleGroup_horizontalSlide {
      paddingBottom
      paddingTop
    }
  }
`;
