import React from "react";
import { graphql } from "gatsby";

import Container from "@components/Container";
import MediaElement from "@components/MediaElement";
import "./socialProofBlock.scss";

export default function SocialProofBlock({ block }) {
  let companies = [];

  Object.entries(block.companies).forEach(([key, value]) => {
    companies.push(
      <div className="company" key={value.companyImage.id}>
        <MediaElement
          media={value.companyImage}
          notLazy={true}
          // aspectRatio={true}
          fit="contain"
        />
      </div>
    );
  });

  return (
    <section
      data-scroll-section
      className={`block social-proof-block ${block.styleGroup_socialProof.paddingTop} ${block.styleGroup_socialProof.paddingBottom}`}
    >
      <Container>
        <h2
          className="headline"
          data-scroll
          data-scroll-speed="2"
          dangerouslySetInnerHTML={{ __html: block.title }}
        />
        <div className="companies" data-scroll data-scroll-speed="1">
          {companies}
        </div>
      </Container>
    </section>
  );
}

export const query = graphql`
  fragment SocialProofBlock on PayloadCMS_SocialProofBlock {
    title
    blockName
    blockType
    styleGroup_socialProof {
      paddingBottom
      paddingTop
    }
    companies {
      companyImage {
        ...Media
      }
    }
  }
`;
