// import React from "react";
import React, { useEffect } from "react";
import { Link } from "gatsby";

import Container from "./Container";
import "./footer.scss";

export default function Footer({ footerOptions, nav }) {
  useEffect(() => {
    /**
     * Link Hover effect
     */
    document.querySelectorAll(".letter-link").forEach((link) => {
      link.innerHTML =
        "<div><span>" +
        link.textContent
          .trim()
          .split("")
          .join("</span><span>") +
        "</span></div>";
      link
        .querySelectorAll("span")
        .forEach(
          (s) =>
            (s.innerHTML = s.textContent === " " ? "&nbsp;" : s.textContent)
        );
      link.insertAdjacentHTML(
        "beforeend",
        '<div><svg preserveAspectRatio="none" viewBox="0 0 192 5"><path d="M191.246 4H129C129 4 127.781 4.00674 127 4C114.767 3.89447 108.233 1 96 1C83.7669 1 77.2327 3.89447 65 4C64.219 4.00674 63 4 63 4H0.751923" /></svg></div>'
      );
    });
  });

  /**
   * Social Media
   */
  const socials = [];
  Object.entries(footerOptions.socialmedia).forEach(([key, value]) => {
    socials.push(
      <li key={key}>
        <p>
          <a
            href={value.link}
            target="_blank"
            className="letter-link"
            rel="noreferrer"
          >
            {value.label}
          </a>
        </p>
      </li>
    );
  });

  /**
   * Footer Nav (Privacy, etc)
   */
  const footerNavList = [];
  nav.footerNav.forEach((item, i) => {
    if (item.page.id === nav.homepage.id) {
      item.page.slug = "";
    }
    footerNavList.push(
      <li key={i}>
        <p>
          <Link className="letter-link" to={"/" + item.page.slug}>
            {item.page.title}
          </Link>
        </p>
      </li>
    );
  });

  /**
   * render
   */
  return (
    <footer className="main-footer" data-scroll-section>
      <div className="fixed_wrapper" data-scroll data-scroll-repeat>
        <div className="fixed_target" id="fixed-target"></div>
        <div
          className="fixed"
          data-scroll
          data-scroll-sticky
          data-scroll-target="#fixed-target"
        >
          <Container>
            <div
              className="footer__container-inner"
              data-scroll
              data-scroll-speed="2"
            >
              <h3
                className="contact-headline"
                dangerouslySetInnerHTML={{ __html: footerOptions.title }}
              />
              <div className="contact-email">
                <div className="label">Start a conversation</div>
                <ul>
                  <li>
                    <p>
                      <a
                        href={`mailto:${footerOptions.email}`}
                        target="_blank"
                        className="letter-link"
                        rel="noreferrer"
                      >
                        {footerOptions.email}
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="contact-socialmedia">
                <div className="label"> or follow me on social media</div>
                <ul>{socials}</ul>
              </div>
            </div>
          </Container>
          <div
            className="footer__container-bottom"
            data-scroll
            data-scroll-speed="3"
          >
            <div className="base-container footer__container-bottom-inner">
              <p className="copyright">{footerOptions.copyright}</p>
              <div className="bottom-nav">
                <ul>{footerNavList}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
