import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import cn from "classnames";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import SplitText from "@src/vendors/Split3.min";
import useOnScreen from "@src/hooks/useOnScreen";
import useWindowSize from "@src/hooks/useWindowSize";

import Container from "@components/Container";
import MediaElement from "@components/MediaElement";
import "./landing2block.scss";

export default function LandingBlock({ block }) {
  const size = useWindowSize();
  const scrollId = "landing2";
  let tl = gsap.timeline({});

  const assetRef = useRef(null);
  const imageReveal = useRef(null);
  const landing2Ref = useRef(null);
  const headline = useRef(null);

  const [reveal, setReveal] = useState(false);
  const [animComplete, setAnimComplete] = useState(false);
  const [textAnimStarted, setTextAnimStarted] = useState(false);
  const [textAnimComplete, setTextAnimComplete] = useState(false);
  const onScreen = useOnScreen(headline);

  // Image pin
  useEffect(() => {
    let killed;
    // if (reveal) {
    setTimeout(() => {
      ScrollTrigger.matchMedia({
        // desktop
        "(min-width: 1025px)": () => {
          if (!killed) {
            tl.to(imageReveal.current, {
              duration: 1,
              width: "0",
              ease: "power2.inOut",
            })
              .add("text")
              .from(
                assetRef.current.querySelector("img"),
                {
                  scale: 1.4,
                  ease: "power2.inOut",
                  onComplete: () => {
                    if(assetRef.current) {
                      assetRef.current.querySelector("img").classList.add("done");
                    }
                  },
                },
                "-=0.75"
              )
              .to(landing2Ref.current, {
                scrollTrigger: {
                  id: scrollId,
                  trigger: assetRef.current,
                  // markers: true,
                  pin: true,
                  scrub: true,
                  scroller: "#___gatsby",
                  start: "top top",
                  end: () => landing2Ref.current.clientHeight * 0.6,
                  invalidateOnRefresh: true,
                  onRefresh: () => {
                    tl.progress(0);
                  },
                },
              });
          }
        },

        // mobile
        "(max-width: 1024px)": () => {
          if (!killed) {
            tl.to(assetRef.current, {
              opacity: 0.6,
              duration: 1.5,
              delay: 0.3,
              ease: "power4.inOut",
            })
              .to(
                assetRef.current,
                {
                  width: "60vw",
                  duration: 1.5,
                  ease: "power4.inOut",
                  onComplete: () => {
                    setAnimComplete(true);
                  },
                },
                "-=1.5"
              )
              .add("text")
              .to(landing2Ref.current, {
                scrollTrigger: {
                  trigger: assetRef.current,
                  invalidateOnRefresh: true,
                  // markers: true,
                  pin: true,
                  scroller: window,
                  scrub: true,
                  start: "top top",
                  end: "bottom 20%",
                  onRefresh: () => {
                    tl.progress(0);
                  },
                },
              });
          }
        },
      });
      ScrollTrigger.refresh();
    });
    // }
    return () => {
      ScrollTrigger.getAll().forEach((instance) => {
        if (instance.vars.id === scrollId) {
          instance.kill();
        }
      });
      killed = true;
      // This in case a scroll animation is active while the route is updated
      // gsap.killTweensOf(window);
    };
  }, [size, tl]);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);

  // Text reveal
  useEffect(() => {
    let split;

    if (reveal && !textAnimComplete && !textAnimStarted) {
      split = new SplitText(headline.current, { type: "lines" });

      setTextAnimStarted(true);
      tl.to(
        split.lines,
        {
          duration: 1.2,
          y: 0,
          opacity: 1,
          stagger: 0.1,
          ease: "power4.out",
          onComplete: () => {
            split.revert();
            setTextAnimComplete(true);
          },
        },
        size.width < 1024 ? "text+=1" : "text+=0.8"
      );
    }

    return () => {};
  }, [reveal, tl, size, textAnimComplete, textAnimStarted]);

  let asset;
  if (block.asset) {
    asset = (
      <div
        className={`asset-wrapper ${cn({
          "anim-complete": animComplete && size.width < 1024,
        })}`}
        ref={assetRef}
      >
        <div className="imageReveal" ref={imageReveal}></div>
        <MediaElement
          media={block.asset}
          // scrollSpeed="2"
          notLazy={true}
          fit="cover"
        />
      </div>
    );
  }
  return (
    <section
      data-scroll-section
      className={`block landing-block-2 ${cn({
        "anim-complete": animComplete,
      })}`}
      ref={landing2Ref}
    >
      <Container>
        <h1
          className={`
          headline 
          ${block.styleGroup_landing_2.paddingTop} 
          ${block.styleGroup_landing_2.paddingBottom}
          ${cn({ "is-reveal": reveal })}
          `}
          data-scroll
          data-scroll-speed="2"
          data-splitting="true"
          ref={headline}
        >
          {block.title}
        </h1>
      </Container>
      {asset}
    </section>
  );
}

export const query = graphql`
  fragment Landing2Block on PayloadCMS_Landing2Block {
    styleGroup_landing_2 {
      paddingTop
      paddingBottom
    }
    title
    blockType
    blockName
    asset {
      ...Media
    }
  }
`;
