import React from "react";
import "./customlink.scss";

import { Link } from "gatsby";
import ArrowRight from "@assets/svg/arrow-right.svg";
import CircleOutline from "@assets/svg/circle-outline.svg";

export default function CustomLink({ linkData }) {
  let link = null;

  switch (linkData.link_type) {
    case "page":
      link = (
        <Link className="link" to={`/${linkData.link_target_page.slug}`}>
          {linkData.link_label}
          <span className="icon">
            <CircleOutline />
            <ArrowRight />
          </span>
        </Link>
      );
      break;
    case "project":
      link = (
        <Link
          className="link"
          to={`/projects/${linkData.link_target_project.slug}`}
        >
          {linkData.link_label}
          <span className="icon">
            <CircleOutline />
            <ArrowRight />
          </span>
        </Link>
      );
      break;
    case "external":
      link = (
        <a
          className="link"
          href={linkData.link_target_external}
          target="_blank"
          rel="noreferrer"
        >
          {linkData.link_label}
          <span className="icon">
            <CircleOutline />
            <ArrowRight />
          </span>
        </a>
      );
      break;
    case "hash":
      break;
    default:
      break;
  }

  return <div className="link-wrapper">{link}</div>;
}
