import React, { useEffect, useRef } from "react";
import cn from "classnames";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import Container from "@components/Container";
import MediaElement from "@components/MediaElement";
import useOnScreen from "@src/hooks/useOnScreen";
import useWindowSize from "@src/hooks/useWindowSize";
// import CustomLink from "@components/CustomLink";

import "./horizontalslideblock.scss";

function GalleryItem({ slide, index }) {
  const ref = useRef(null);
  const onScreen = useOnScreen(ref, 0.2);

  return (
    <div
      className={cn("gallery-item-wrapper", { "is-reveal": onScreen })}
      ref={ref}
      key={index + slide.textGroup.title}
    >
      <div className="container-wrapper">
        <Container>
          <div className="horizontal-slide__content">
            {slide.textGroup.pretitle ? (
              <h5
                className="pretitle wide-font"
                dangerouslySetInnerHTML={{ __html: slide.textGroup.pretitle }}
              />
            ) : null}
            {slide.textGroup.title ? (
              <h2
                className="title"
                dangerouslySetInnerHTML={{ __html: slide.textGroup.title }}
              />
            ) : null}
            {slide.textGroup.copy ? (
              <p className="copy"> {slide.textGroup.copy}</p>
            ) : null}
          </div>
          {slide.asset ? (
            <div className="asset-wrapper">
              <MediaElement
                media={slide.asset}
                notLazy={true}
                hasRevealAnim={true}
              />
            </div>
          ) : null}
        </Container>
      </div>
    </div>
  );
}

export default function HorizontalSlideDesktop({ block }) {
  const scrollId = "horizontalDesktop";
  const ref = useRef(null);
  const size = useWindowSize();

  useEffect(() => {
    let killed;
    // This does not seem to work without a settimeout
    setTimeout(() => {
      ScrollTrigger.matchMedia({
        // desktop
        "(min-width: 1024px)": () => {
          if (!killed) {
            gsap.to(ref.current, {
              x: -ref.current.offsetWidth + window.innerWidth,
              ease: "none",
              scrollTrigger: {
                id: scrollId,
                scroller: "#___gatsby",
                trigger: ref.current,
                // markers: true,
                pin: true,
                start: "top top",
                scrub: 0.2,
                // snap: 1 / block.slides.length,
                end: () => ref.current.offsetWidth,
              },
            });
          }

          ScrollTrigger.refresh();
        },
      });
    });

    return () => {
      ScrollTrigger.getAll().forEach((instance) => {
        if (instance.vars.id === scrollId) {
          instance.kill();
        }
      });
      killed = true;
    };
  }, [size]);

  return (
    <div className="gallery" ref={ref}>
      {block.slides &&
        block.slides.map((slide, index) => (
          <GalleryItem
            key={index + slide.textGroup.title}
            index={index}
            slide={slide}
          />
        ))}
    </div>
  );
}
