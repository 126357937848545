import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "../layouts/Layout";
import BlockComponent from "../components/BlockComponent";

// markup
const PageTemplate = ({ data }) => {
  if (!data) return null;
  const document = data.cms.Page;

  //block loop
  const Blocks = [];
  document.blocks.forEach((block, i) => {
    if (block) {
      Blocks.push(<BlockComponent {...block} key={i} />);
    }
  });

  return (
    <Layout>
      {/* SEO */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{document.title}</title>
        <link rel="canonical" href={process.env.GATSBY_FRONTEND_URL} />
        <script
          defer
          data-domain="dtampe.com"
          src="https://analytics.dtampe.com/js/plausible.js"
        ></script>
      </Helmet>
      {Blocks}
      <div className="footer-spacer" />
    </Layout>
  );
};

export const query = graphql`
  query SinglePage($id: String) {
    cms {
      Page(id: $id) {
        slug
        blocks {
          ...LandingBlock
          ...Landing2Block
          ...FullWidthTeaserBlock
          ...TeaserBlock
          ...SocialProofBlock
          ...HorizontalSlideBlock
        }
        title
      }
    }
  }
`;

// ...SelectedWorkBlock

export default PageTemplate;
