import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { gsap } from "gsap";
import cn from "classnames";

import "./fullWidthTeaserBlock.scss";
import useOnScreen from "@src/hooks/useOnScreen";
import MediaElement from "@components/MediaElement";

export default function FullWidthTeaserBlock({ block }) {
  const wrapper = useRef(null);
  const [width, setWidth] = useState(0);
  const onScreen = useOnScreen(wrapper);

  const [animStarted, setAnimStarted] = useState(false);
  const [animComplete, setAnimComplete] = useState(false);
  const [reveal, setReveal] = useState(false);
  const breakpoint = 1024;

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);

  // Text reveal
  useEffect(() => {
    if (block.hasLandingAnimation && reveal && !animComplete && !animStarted) {
      setAnimStarted(true);
      const tl = gsap.timeline({
        onComplete: () => {
          setAnimComplete(true);
        },
      });
      tl.to(wrapper.current.querySelector(".img-overlay"), {
        duration: width < 1024 ? 2.5 : 4,
        delay: width < 1024 ? 0.25 : 0.75,
        height: 0,
        ease: "expo.out",
      }).from(
        wrapper.current.querySelector(".media-element__wrapper"),
        {
          duration: width < 1024 ? 1 : 1.5,
          yPercent: 80,
          ease: "expo.out",
        },
        0
      );
    }

    setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    return () => {
      setWidth(0);
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  }, [reveal, block.hasLandingAnimation, animComplete, animStarted, width]);

  // useEffect(() => {}, []);
  return (
    <section
      data-scroll-section
      className={`block full-width-teaser-block ${
        block.styleGroup_fwt.paddingTop
      } ${block.styleGroup_fwt.paddingBottom} ${block.styleGroup_fwt.height} ${
        block.teaserMobile ? "has-mobile-asset" : ""
      } ${cn({ "is-reveal": reveal })}
      `}
      ref={wrapper}
    >
      {block.hasLandingAnimation ? (
        <div
          className={`img-overlay ${cn({ "anim-complete": animComplete })}`}
        ></div>
      ) : (
        ""
      )}

      {width < breakpoint && block.teaserMobile !== null ? (
        <MediaElement
          media={block.teaserMobile}
          mobile
          aspectRatio
          notLazy={!block.lazy}
        />
      ) : (
        <MediaElement
          media={block.teaser}
          desktop
          scrollSpeed="1.5"
          notLazy={!block.lazy}
        />
      )}
    </section>
  );
}

export const query = graphql`
  fragment FullWidthTeaserBlock on PayloadCMS_FullWidthTeaserBlock {
    blockName
    blockType
    lazy
    hasLandingAnimation
    teaser {
      ...Media
    }
    teaserMobile {
      ...Media
    }
    styleGroup_fwt {
      height
      paddingBottom
      paddingTop
    }
  }
`;
