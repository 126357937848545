import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Header from "@components/Header";
import Footer from "@components/Footer";
import useLocoScroll from "../hooks/useLocoScroll";

import "./layout.scss";

const GlobalLayout = (props) => {
  // init locomotive scroll
  useLocoScroll();

  return (
    <div>
      <Header nav={props.query.nav} />
      <main className="content">{props.children}</main>
      <Footer footerOptions={props.query.footer} nav={props.query.nav} />
    </div>
  );
};

const query = (props) => (
  <StaticQuery
    query={graphql`
      query MyQuery {
        cms {
          Menus {
            footerNav {
              page {
                slug
                id
                title
              }
            }
            homepage {
              slug
              id
              title
            }
            items {
              page {
                id
                slug
                title
              }
            }
          }
          Footer {
            copyright
            socialmedia {
              label
              link
            }
            title
            email
          }
        }
      }
    `}
    render={({ cms }) => (
      <GlobalLayout query={{ nav: cms.Menus, footer: cms.Footer }} {...props} />
    )}
  />
);
export default query;
