import React from "react";
import { Link } from "gatsby";

import * as style from "./header.module.scss";
import Container from "./Container";

export default function Header({ nav }) {
  const list = [];

  nav.items.forEach((item, i) => {
    if (item.page.id === nav.homepage.id) {
      item.page.slug = "";
    }
    list.push(
      <li key={i}>
        <Link to={"/" + item.page.slug}>{item.page.title}</Link>
      </li>
    );
  });

  return (
    <header className={style.header}>
      <Container>
        <div className={style.wrapper + " w-full"}>
          <div className={style.logo}>
            <Link to={"/"}>d. tampe</Link>
          </div>
          <ul className={style.mainNav}>{list}</ul>
        </div>
      </Container>
    </header>
  );
}
