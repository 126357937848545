import React, { Component } from "react";
import LandingBlock from "@blocks/landingBlock/LandingBlock";
import Landing2Block from "@blocks/landing2Block/Landing2Block";
import FullWidthTeaserBlock from "@blocks/fullWidthTeaserBlock/FullWidthTeaserBlock";
import TeaserBlock from "@blocks/teaserBlock/TeaserBlock";
import SocialProofBlock from "@blocks/socialProofBlock/SocialProofBlock";
import HorizontalSlideBlock from "@blocks/horizontalSlideBlock/HorizontalSlideBlock";
// import SelectedWorkBlock from "@blocks/selectedWorkBlock/SelectedWorkBlock";

class BlockComponent extends Component {
  components = {
    LandingBlock,
    Landing2Block,
    FullWidthTeaserBlock,
    TeaserBlock,
    SocialProofBlock,
    HorizontalSlideBlock,
    // SelectedWorkBlock,
  };

  render() {
    const TagName = this.components[this.props.blockType];
    if (TagName) {
      // TODO: DELETE LOG LATER
      // console.log(this.props.blockType + ":", this.props);
      return <TagName block={this.props} />;
    }
    return null;
  }
}

export default BlockComponent;
