import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { gsap } from "gsap";
import cn from "classnames";

import SplitText from "@src/vendors/Split3.min";
import useOnScreen from "@src/hooks/useOnScreen";
import useWindowSize from "@src/hooks/useWindowSize";

import Container from "@components/Container";
import MediaElement from "@components/MediaElement";
import "./landingblock.scss";

export default function LandingBlock({ block }) {
  const headline = useRef(null);
  const pretitleRef = useRef(null);
  const onScreen = useOnScreen(headline);
  let tl = gsap.timeline({
    paused:true,
    onComplete: () => {
      setAnimComplete(true);
    },
  });
  const size = useWindowSize();

  const [animStarted, setAnimStarted] = useState(false);
  const [animComplete, setAnimComplete] = useState(false);
  const [reveal, setReveal] = useState(false);
  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);

  // Text reveal
  useEffect(() => {
    let split;

    if (reveal && !animComplete && !animStarted) {
      setAnimStarted(true);

      split = new SplitText(headline.current, { type: "lines" });
      split.lines.forEach((line) => {
        const el = document.createElement("span");
        const text = line.textContent;
        line.textContent = "";
        line.parentNode.insertBefore(document.createElement("span"), line);
        line.appendChild(el);
        el.innerText = text;
        line.style.height = line.clientHeight + 2 + "px";
      });

      if (block.enablePretitle) {
        tl.from(pretitleRef.current, {
          duration: size.width < 1024 ? 0.8 : 1.2,
          yPercent: 50,
          opacity: 0,
          ease: "expo.out",
        });
      }

      tl.from(
        headline.current.querySelectorAll("div span"),
        {
          duration: size.width < 1024 ? 0.8 : 1.2,
          yPercent: 80,
          skewY: 7,
          opacity: 0,
          stagger: size.width < 1024 ? 0.05 : 0.15,
          ease: "expo.out",
          onComplete: () => {
            split.revert();
          },
          // onComplete: () =>
        },
        0
      );

      tl.play()
    }

    return () => {};
  }, [reveal, tl, animComplete, animStarted, size, block.enablePretitle]);

  let pretitle = "";
  if (block.enablePretitle) {
    if (
      block.pretitleGroup.usePretitleImage &&
      block.pretitleGroup.pretitleImage !== null
    ) {
      pretitle = (
        <div className="pretitle-image" data-scroll-speed="3">
          <MediaElement media={block.pretitleGroup.pretitleImage} aspectRatio />
        </div>
      );
    } else {
      pretitle = (
        <h5 className="pretitle" data-scroll data-scroll-speed="3">
          {block.pretitleGroup.pretitle}
        </h5>
      );
    }
  }

  return (
    <section
      data-scroll-section
      className={`block landing-block ${block.styleGroup_landing.paddingTop} ${block.styleGroup_landing.paddingBottom}`}
    >
      <Container>
        <div className="pretitle" ref={pretitleRef}>
          {pretitle}
        </div>
        <h1
          className={`
          headline 
          ${cn({ "is-reveal": reveal })}
          ${cn({
            "anim-complete": animComplete,
          })}
          `}
          data-scroll
          data-scroll-speed="2"
          ref={headline}
        >
          {block.title}
        </h1>
      </Container>
    </section>
  );
}

export const query = graphql`
  fragment LandingBlock on PayloadCMS_LandingBlock {
    styleGroup_landing {
      paddingTop
      paddingBottom
    }
    title
    enablePretitle
    blockType
    blockName
    pretitleGroup {
      pretitle
      usePretitleImage
      pretitleImage {
        ...Media
      }
    }
  }
`;
