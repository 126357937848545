import React from "react";

import Container from "@components/Container";
import MediaElement from "@components/MediaElement";
// import useWindowSize from "@src/hooks/useWindowSize";

import "./horizontalslideblock.scss";

function SlideItem({ slide }) {
  return (
    <div className="slide-item">
      <Container>
        <div className="horizontal-slide__content">
          {slide.textGroup.pretitle ? (
            <h5
              className="pretitle wide-font"
              dangerouslySetInnerHTML={{ __html: slide.textGroup.pretitle }}
            />
          ) : null}
          {slide.textGroup.title ? (
            <h2
              className="title"
              dangerouslySetInnerHTML={{ __html: slide.textGroup.title }}
            />
          ) : null}
          {slide.textGroup.copy ? (
            <p className="copy"> {slide.textGroup.copy}</p>
          ) : null}
        </div>
        {slide.asset ? (
          <div className="asset-wrapper">
            <MediaElement
              media={slide.asset}
              notLazy={true}
              hasRevealAnim={true}
            />
          </div>
        ) : null}
      </Container>
    </div>
  );
}

export default function HorizontalSlideMobile({ block }) {
  return (
    <div className="gallery-mobile">
      {block.slides &&
        block.slides.map((slide, index) => (
          <SlideItem key={index + slide.textGroup.title} slide={slide} />
        ))}
    </div>
  );
}
