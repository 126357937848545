import React from "react";
import { graphql } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { isBrowser } from "react-device-detect";
import cn from "classnames";

import "./mediaElement.scss";

export default function MediaElement({
  media,
  aspectRatio,
  fit,
  notLazy,
  scrollSpeed,
  hasRevealAnim,
}) {
  // const baseUrl = process.env.GATSBY_STORAGE_URL;
  const baseUrl = process.env.GATSBY_FRONTEND_URL + "/static/";
  const mimeType = media.mimeType.split("/")[0];
  const ratio = aspectRatio ? (media.height / media.width) * 100 + "%" : "";
  const objectFit = !fit ? "cover" : fit;

  const updateScroll = () =>
    isBrowser && window.scroll !== undefined && window.scroll.update();
  const afterLoad = () => {
    if (!notLazy) {
      updateScroll();
    }
  };

  let mediaEle = "";

  if (mimeType === "image" && media.mimeType !== "image/svg+xml") {
    let srcSet = "";
    for (const [key, size] of Object.entries(media.sizes)) {
      if (key !== "blur" && size !== null) {
        srcSet += `${baseUrl + size.filename} ${size.width}w, `;
      }
    }

    mediaEle = (
      <LazyLoadImage
        className={cn("media-element__image", {
          "has-reveal-anim": hasRevealAnim,
        })}
        alt={media.alt}
        style={{
          objectFit: objectFit,
        }}
        effect={notLazy ? "" : "blur"}
        srcSet={srcSet.length > 0 ? srcSet : ""}
        src={srcSet.length <= 0 ? baseUrl + media.filename : ""}
        placeholderSrc={baseUrl + media.sizes.blur.filename}
        wrapperClassName="lazy-wrapper"
        delayTime={100}
        visibleByDefault={notLazy}
        data-scroll
        data-scroll-speed={scrollSpeed ? -scrollSpeed * 2 : undefined}
        afterLoad={afterLoad}
        threshold={200}
      />
    );
  } else if (media.mimeType === "image/svg+xml") {
    mediaEle = (
      <img
        src={baseUrl + media.filename}
        alt={media.alt}
        data-scroll
        data-scroll-speed={scrollSpeed ? -scrollSpeed * 2 : undefined}
      />
    );
  } else if (mimeType === "video") {
    mediaEle = (
      <LazyLoadComponent
        visibleByDefault={notLazy}
        delayTime={100}
        afterLoad={afterLoad}
        threshold={300}
      >
        <video
          className={
            (cn("media-element__video"), { "has-reveal-anim": hasRevealAnim })
          }
          autoPlay
          disableRemotePlayback
          loop
          muted
          src={baseUrl + media.filename}
        />
      </LazyLoadComponent>
    );
  }

  return (
    <div
      data-scroll
      data-scroll-speed={scrollSpeed}
      className={`media-element ${aspectRatio ? "ratio" : ""} ${objectFit} ${
        notLazy ? "not-lazy" : ""
      }`}
      style={{ paddingTop: ratio }}
    >
      <div className={`media-element__wrapper`}>{mediaEle}</div>
    </div>
  );
}

export const query = graphql`
  fragment Media on PayloadCMS_Media {
    width
    alt
    filesize
    filename
    height
    id
    mimeType
    sizes {
      blur {
        filesize
        filename
      }
      m {
        filesize
        filename
        height
        width
      }
      l {
        filename
        width
        height
        filesize
      }
      s {
        filename
        filesize
        height
        width
      }
      xl {
        filename
        width
        height
        filesize
      }
      xs {
        filename
        filesize
        height
        width
      }
      xxl {
        filename
        filesize
        height
        width
      }
      xxxl {
        filename
        filesize
        height
        width
      }
    }
  }
`;
